import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-general-14',
  templateUrl: './14.component.html',
  styleUrls: ['./14.component.scss'],
})
export class VbGeneral14Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
