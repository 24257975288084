import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-bootstrap-popover-example',
  templateUrl: './popover.component.html',
})
export class KitBootstrapPopoverExampleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
