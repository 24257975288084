import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-general-13v1',
  templateUrl: './13v1.component.html',
  styleUrls: ['./13v1.component.scss'],
})
export class VbGeneral13v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
