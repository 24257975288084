<h5 class="mb-3">
  <strong>Basic</strong>
</h5>
<div class="mb-5">
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      Home
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a>Application List</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      An Application
    </nz-breadcrumb-item>
  </nz-breadcrumb>
</div>
<h5 class="mb-3">
  <strong>With an Icon</strong>
</h5>
<div class="mb-5">
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <i nz-icon nzType="home"></i>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a><i nz-icon nzType="user"></i><span>Application List</span></a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      Application
    </nz-breadcrumb-item>
  </nz-breadcrumb>
</div>