<div>
  <div class="row">
    <div class="col-md-4">
      <div class="table-responsive text-nowrap">
        <nz-table #orderBookSellTable nzSize="small" [nzData]="orderBookSell" class="vb__utils__table">
          <thead>
            <tr>
              <th class="text-right">SUM</th>
              <th class="text-right">Total</th>
              <th class="text-right">Size</th>
              <th class="text-right">BID</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of orderBookSellTable.data" class="text-right">
              <td>{{item.sum}}</td>
              <td>{{item.total}}</td>
              <td>{{item.size}}</td>
              <td [ngStyle]="{'color': '#00a45b'}">{{item.bid}}</td>
              <td>
                <a href="javascript: void(0);" class="utils__link--blue mr-2">
                  <strong>SELL</strong>
                </a>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form">
        <form nz-form nzLayout="vertical" class="cui-crypto-dashboard-form">
          <nz-form-item>
            <nz-form-control>
              <nz-radio-group [(ngModel)]="actionType" [ngStyle]="{'width': '100%', 'text-align': 'center'}"
                [ngModelOptions]="{standalone: true}">
                <label nz-radio-button nzValue="buy" [ngStyle]="{'width': '50%', 'text-align': 'center'}">BUY</label>
                <label nz-radio-button nzValue="sell" [ngStyle]="{'width': '50%', 'text-align': 'center'}">SELL</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
          <div *ngIf="actionType === 'buy'">
            <span class="cui-crypto-dashboard-form-label">ORDER TYPE</span>
            <nz-form-item>
              <nz-form-control>
                <nz-select [(ngModel)]="orderType" [ngModelOptions]="{standalone: true}">
                  <nz-option nzValue="limit" nzLabel="Limit (Default)"></nz-option>
                  <nz-option nzValue="conditional" nzLabel="Conditional"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <span class="cui-crypto-dashboard-form-label">QUANTITY (BTC)</span>
            <nz-form-item>
              <nz-form-control>
                <input type="text" nz-input [ngModel]="'0.00000000'" [ngModelOptions]="{standalone: true}">
              </nz-form-control>
            </nz-form-item>
            <span class="cui-crypto-dashboard-form-label">BID PRICE</span>
            <nz-form-item>
              <nz-form-control>
                <input type="text" nz-input [ngModel]="'0.00645198'" [ngModelOptions]="{standalone: true}">
              </nz-form-control>
            </nz-form-item>
            <span class="cui-crypto-dashboard-form-label">TOTAL</span>
            <nz-form-item>
              <nz-form-control>
                <input type="text" nz-input [ngModel]="'0.00000000'" [ngModelOptions]="{standalone: true}">
              </nz-form-control>
            </nz-form-item>
            <span class="cui-crypto-dashboard-form-label">TIME IN FORCE</span>
            <nz-form-item>
              <nz-form-control>
                <nz-select [(ngModel)]="timeInForce" [ngModelOptions]="{standalone: true}">
                  <nz-option nzValue="good" nzLabel="Good &#39;Til Cancelled (Default)"></nz-option>
                  <nz-option nzValue="immediate" nzLabel="Immediate or Cancel"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <button class="btn btn-success" style="width: 100%;">
              <strong>BUY BTC</strong>
            </button>
            <div class="my-3 text-center">
              <div>
                <strong>Available Balance</strong>
              </div>
              <div>12.92520000 BTC</div>
              <div>1450.00 USD</div>
              <div>
                <a href="javascript: void(0);" class="utils__link--blue utils__link--underlined">
                  <strong>Max Buy</strong>
                </a>
              </div>
            </div>
          </div>
          <div *ngIf="actionType === 'sell'">
            <span class="cui-crypto-dashboard-form-label">ORDER TYPE</span>
            <nz-form-item>
              <nz-form-control>
                <nz-select [(ngModel)]="orderType" [ngModelOptions]="{standalone: true}">
                  <nz-option nzValue="limit" nzLabel="Limit (Default)"></nz-option>
                  <nz-option nzValue="conditional" nzLabel="Conditional"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <span class="cui-crypto-dashboard-form-label">QUANTITY (BTC)</span>
            <nz-form-item>
              <nz-form-control>
                <input type="text" nz-input [ngModel]="'0.00000000'" [ngModelOptions]="{standalone: true}">
              </nz-form-control>
            </nz-form-item>
            <span class="cui-crypto-dashboard-form-label">ASK PRICE</span>
            <nz-form-item>
              <nz-form-control>
                <input type="text" nz-input [ngModel]="'0.00645198'" [ngModelOptions]="{standalone: true}">
              </nz-form-control>
            </nz-form-item>
            <span class="cui-crypto-dashboard-form-label">TOTAL</span>
            <nz-form-item>
              <nz-form-control>
                <input type="text" nz-input [ngModel]="'0.00000000'" [ngModelOptions]="{standalone: true}">
              </nz-form-control>
            </nz-form-item>
            <span class="cui-crypto-dashboard-form-label">TIME IN FORCE</span>
            <nz-form-item>
              <nz-form-control>
                <nz-select [(ngModel)]="timeInForce" [ngModelOptions]="{standalone: true}">
                  <nz-option nzValue="good" nzLabel="Good &#39;Til Cancelled (Default)"></nz-option>
                  <nz-option nzValue="immediate" nzLabel="Immediate or Cancel"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <button class="btn btn-danger" style="width: 100%;">
              <strong>SELL BTC</strong>
            </button>
            <div class="my-3 text-center">
              <div>
                <strong>Available Balance</strong>
              </div>
              <div>12.92520000 BTC</div>
              <div>1450.00 USD</div>
              <div>
                <a href="javascript: void(0);" class="utils__link--blue utils__link--underlined">
                  <strong>Max Sell</strong>
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-4">
      <div class="table-responsive text-nowrap">
        <nz-table #orderBookBuyTable [nzData]="orderBookBuy" nzSize="small" class="vb__utils__table">
          <thead>
            <tr>
              <th></th>
              <th>ASK</th>
              <th>Size</th>
              <th>Total</th>
              <th>SUM</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of orderBookBuyTable.data" class="text-left">
              <td class="text-left">
                <a href="javascript: void(0);" class="utils__link--blue ml-2">
                  <strong>BUY</strong>
                </a>
              </td>
              <td class="text-left" [ngStyle]="{'color': '#f75535'}">
                {{item.ask}}
              </td>
              <td class="text-left">{{item.size}}</td>
              <td class="text-left">{{item.total}}</td>
              <td class="text-left">{{item.sum}}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>