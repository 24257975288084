export const getMenuData: any[] = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    title: 'Dashboard',
    key: '__dashboard',
    url: '/dashboard',
    icon: 'fe fe-home',
  },

  // VB:REPLACE-END:MENU-CONFIG
]
