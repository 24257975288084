<div class="mb-5">
  <h5 class="mb-3">
    <strong>Basic</strong>
  </h5>
  <nz-collapse>
    <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active"
      [nzDisabled]="panel.disabled">
      <p style="margin:0;">
        A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome
        guest in many households across the world.
      </p>
    </nz-collapse-panel>
  </nz-collapse>
</div>
<div class="mb-5">
  <h5 class="mb-3">
    <strong>Borderless</strong>
  </h5>
  <nz-collapse [nzBordered]="false">
    <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
      <p>{{ panel.name }} content</p>
    </nz-collapse-panel>
  </nz-collapse>
</div>