import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-bootstrap-rating-example',
  templateUrl: './rating.component.html',
})
export class KitBootstrapRatingExampleComponent implements OnInit {
  currentRate = 8
  constructor() {}

  ngOnInit() {}
}
