<div>
  <h5 class="mb-4"><strong>Input Addons</strong></h5>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">@</span>
    </div>
    <input placeholder="username" type="text" class="form-control">
  </div>
  <br />
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">
        <input aria-label="Checkbox for following text input" type="checkbox" class=""></span>
    </div>
    <input placeholder="Check it out" type="text" class="form-control">
  </div>
  <br />
  <div class="input-group">
    <input placeholder="username" type="text" class="form-control">
    <div class="input-group-append">
      <span class="input-group-text">@example.com</span>
    </div>
  </div>
  <br />
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">$</span>
      <span class="input-group-text">$</span>
    </div>
    <input placeholder="Dolla dolla billz yo!" type="text" class="form-control">
    <div class="input-group-append">
      <span class="input-group-text">$</span>
      <span class="input-group-text">$</span>
    </div>
  </div>
  <br />
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">$</span>
    </div>
    <input placeholder="Amount" min="0" max="100" step="1" type="number" class="form-control">
    <div class="input-group-append">
      <span class="input-group-text">.00</span>
    </div>
  </div>
</div>