<div>
  <div class="position-relative text-center">
    <div class="flag">$560,245.35</div>
    <div class="font-size-70 pt-3 pb-w text-gray-4">
      <i class="fe fe-star"></i>
    </div>
    <h5 class="font-size-24 font-weight-bold mb-1">David Beckham</h5>
    <div class="font-size-18 text-uppercase mb-3">8748-XXXX-1678-5416</div>
    <div class="font-weight-bold font-size-18 text-uppercase mb-4">MASTERCARD</div>
    <div class="border-top pt-3 font-italic">Expires at 03/22</div>
  </div>
</div>