<h5 class="mb-3">
  <strong>Basic</strong>
</h5>
<div class="mb-5">
  <nz-comment nzAuthor="Han Solo" [nzDatetime]="time">
    <nz-avatar nz-comment-avatar nzIcon="user" nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png">
    </nz-avatar>
    <nz-comment-content>
      <p>
        We supply a series of design principles, practical patterns and high quality design resources (Sketch and
        Axure), to help people create their product prototypes beautifully and efficiently.
      </p>
    </nz-comment-content>
    <nz-comment-action>
      <i nz-tooltip nzTooltipTitle="Like" nz-icon nzType="like" [theme]="likes > 0 ? 'twotone' : 'outline'"
        (click)="like()"></i>
      <span class="count like">{{ likes }}</span>
    </nz-comment-action>
    <nz-comment-action>
      <i nz-tooltip nzTooltipTitle="Dislike" nz-icon nzType="dislike" [theme]="dislikes > 0 ? 'twotone' : 'outline'"
        (click)="dislike()"></i>
      <span class="count dislike">{{ dislikes }}</span>
    </nz-comment-action>
    <nz-comment-action>Reply to</nz-comment-action>
  </nz-comment>
</div>
<h5 class="mb-3">
  <strong>Nested</strong>
</h5>
<div class="mb-5">
  <ng-template #commentTemplateRef let-comment="comment">
    <nz-comment [nzAuthor]="comment.author">
      <nz-avatar nz-comment-avatar nzIcon="user" [nzSrc]="comment.avatar"></nz-avatar>
      <nz-comment-content>
        <p>{{ comment.content }}</p>
      </nz-comment-content>
      <nz-comment-action>Reply to</nz-comment-action>
      <ng-container *ngIf="comment.children && comment.children.length">
        <ng-template ngFor let-child [ngForOf]="comment.children">
          <ng-template [ngTemplateOutlet]="commentTemplateRef" [ngTemplateOutletContext]="{ comment: child }">
          </ng-template>
        </ng-template>
      </ng-container>
    </nz-comment>
  </ng-template>

  <ng-template [ngTemplateOutlet]="commentTemplateRef" [ngTemplateOutletContext]="{ comment: data }"> </ng-template>
</div>