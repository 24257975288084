<form nz-form [nzLayout]="'vertical'">
  <div class="row">
    <div class="col-md-6">
      <nz-form-item>
        <nz-form-label nzFor="email3">Email</nz-form-label>
        <nz-form-control>
          <input nz-input placeholder="Email" id="email3" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-6">
      <nz-form-item>
        <nz-form-label nzFor="password3">Password</nz-form-label>
        <nz-form-control>
          <input nz-input [type]="'password'" placeholder="Password" id="password3" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-6">
      <nz-form-item>
        <nz-form-label nzFor="address31">Address</nz-form-label>
        <nz-form-control>
          <input nz-input placeholder="1234 Main St." id="address31" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-6">
      <nz-form-item>
        <nz-form-label nzFor="address32">Address 2</nz-form-label>
        <nz-form-control>
          <input nz-input placeholder="Apartment, studio, or floor" id="address32" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-6">
      <nz-form-item>
        <nz-form-label nzFor="city3">City</nz-form-label>
        <nz-form-control>
          <input nz-input placeholder="City" id="city3" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-2">
      <nz-form-item>
        <nz-form-label nzFor="state3">State</nz-form-label>
        <nz-form-control>
          <input nz-input placeholder="State" id="state3" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-md-4">
      <nz-form-item>
        <nz-form-label nzFor="zip3">ZIP</nz-form-label>
        <nz-form-control>
          <input nz-input placeholder="State" id="zip3" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-12">
      <nz-form-item>
        <nz-upload nzType="drag" [nzMultiple]="true" [nzLimit]="2"
          nzAction="https://jsonplaceholder.typicode.com/posts/">
          <p class="ant-upload-drag-icon">
            <i nz-icon nzType="inbox"></i>
          </p>
          <p class="ant-upload-text">Click or drag file to this area to upload</p>
          <p class="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
          </p>
        </nz-upload>
      </nz-form-item>
      <nz-form-item>
        <button type="button" class="btn btn-light px-5">
          Sign in
        </button>
      </nz-form-item>
    </div>
  </div>
</form>