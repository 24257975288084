<div>
  <div class="d-flex flex-wrap-reverse align-items-center pb-3">
    <div class="mr-auto">
      <div class="text-uppercase font-weight-bold font-size-24 text-dark">
        IBAN 4658-1235-1567-8000
      </div>
      <div class="font-size-18">
        $12,136.78
      </div>
    </div>
    <div class="flex-shrink-0 font-size-36 text-gray-4 pl-1">
      <i class="fe fe-server"></i>
    </div>
  </div>
  <div class="font-italic font-size-14 text-center border-top pt-3">
    Current month charged: 12,136.78
  </div>
</div>