<div class="d-flex flex-wrap mb-3">
  <div class="mr-5 flex-grow-1">
    <div class="text-uppercase text-dark font-weight-bold mb-3">Calendars</div>
    <div class="d-flex flex-wrap">
      <label nz-checkbox [ngModel]="true" class="event">Gmail</label>
      <label nz-checkbox class="event">Birthdays</label>
    </div>
  </div>
  <div class="flex-grow-1">
    <div class="text-uppercase text-dark font-weight-bold mb-3">Events</div>
    <div class="d-flex flex-wrap">
      <div class="event">
        <div class="vb__utils__donut vb__utils__donut--danger"></div>
        Meeting
      </div>
      <div class="event">
        <div class="vb__utils__donut vb__utils__donut--primary"></div>
        Holidays
      </div>
      <div class="event">
        <div class="vb__utils__donut vb__utils__donut--orange"></div>
        Milestones
      </div>
      <div class="event">
        <div class="vb__utils__donut vb__utils__donut--success"></div>
        Conference
      </div>
    </div>
  </div>
</div>
<nz-calendar>
  <ul *nzDateCell="let date" class="events">
    <ng-container [ngSwitch]="date.getDate()">
      <ng-container *ngSwitchCase="8">
        <li *ngFor="let item of listDataMap.eight">
          <nz-badge [nzStatus]="item.type" [nzText]="item.content"></nz-badge>
        </li>
      </ng-container>
      <ng-container *ngSwitchCase="10">
        <li *ngFor="let item of listDataMap.ten">
          <nz-badge [nzStatus]="item.type" [nzText]="item.content"></nz-badge>
        </li>
      </ng-container>
      <ng-container *ngSwitchCase="11">
        <li *ngFor="let item of listDataMap.eleven">
          <nz-badge [nzStatus]="item.type" [nzText]="item.content"></nz-badge>
        </li>
      </ng-container>
    </ng-container>
  </ul>
  <ng-container *nzMonthCell="let month">
    <div *ngIf="getMonthData(month) as monthData" class="notes-month">
      <section>{{ monthData }}</section>
      <span>Backlog number</span>
    </div>
  </ng-container>
</nz-calendar>