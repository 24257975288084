import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-app-partials-do-plan',
  templateUrl: './do-plan.component.html',
  styleUrls: ['./do-plan.component.scss'],
})
export class VbAppPartialsDoPlanComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
