<div>
  <nz-tabset class="tabs vb-tabs-bordered" [nzSelectedIndex]="0">
    <nz-tab nzTitle="Alerts">
      <div class="text-gray-6">
        <ul class="list-unstyled">
          <li class="mb-3">
            <div class="head">
              <p class="title">
                Update Status:
                <strong class="text-black">New</strong>
              </p>
              <time class="time">5 min ago</time>
            </div>
            <p>Mary has approved your quote.</p>
          </li>
          <li class="mb-3">
            <div class="head">
              <p class="title">
                Update Status:
                <strong class="text-danger">Rejected</strong>
              </p>
              <time class="time">15 min ago</time>
            </div>
            <p>Mary has declined your quote.</p>
          </li>
          <li class="mb-3">
            <div class="head">
              <p class="title">
                Payment Received:
                <strong class="text-black">$5,467.00</strong>
              </p>
              <time class="time">15 min ago</time>
            </div>
            <p>GOOGLE, LLC AUTOMATED PAYMENTS PAYMENT</p>
          </li>
          <li class="mb-3">
            <div class="head">
              <p class="title">
                Notification:
                <strong class="text-danger">Access Denied</strong>
              </p>
              <time class="time">5 Hours ago</time>
            </div>
            <p>The system prevent login to your account</p>
          </li>
          <li class="mb-3">
            <div class="head">
              <p class="title">
                Payment Received:
                <strong class="text-black">$55,829.00</strong>
              </p>
              <time class="time">1 day ago</time>
            </div>
            <p>GOOGLE, LLC AUTOMATED PAYMENTS PAYMENT</p>
          </li>
          <li class="mb-3">
            <div class="head">
              <p class="title">
                Notification:
                <strong class="text-danger">Access Denied</strong>
              </p>
              <time class="time">5 Hours ago</time>
            </div>
            <p>The system prevent login to your account</p>
          </li>
        </ul>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Events">
      <div class="text-center mb-3 py-4 bg-light rounded">No Events</div>
    </nz-tab>
    <nz-tab nzTitle="Actions">
      <div class="text-center mb-3 py-4 bg-light rounded">No Actions</div>
    </nz-tab>
  </nz-tabset>
</div>