<div class="d-flex flex-wrap">
  <div class="mb-3">
    <div class="vb__utils__avatarGroup mb-3">
      <div class="vb__utils__avatar vb__utils__avatar--size46">
        <img src="assets/images/avatars/1.jpg" alt="User 1" />
      </div>
      <div class="vb__utils__avatar vb__utils__avatar--size46">
        <img src="assets/images/avatars/2.jpg" alt="User 2" />
      </div>
      <div class="vb__utils__avatar vb__utils__avatar--size46">
        <img src="assets/images/avatars/3.jpg" alt="User 3" />
      </div>
      <div class="vb__utils__avatar vb__utils__avatar--size46">
        <img src="assets/images/avatars/4.jpg" alt="User 4" />
      </div>
      <button type="button" class="vb__utils__avatarGroupAdd">
        <i class="fe fe-plus"></i>
      </button>
    </div>
    <div class="d-flex align-items-start">
      <div class="vb__utils__avatar vb__utils__avatar--size64 mr-2">
        <img src="assets/images/avatars/4.jpg" alt="User 5" />
      </div>
      <div class="vb__utils__avatar vb__utils__avatar--size46">
        <img src="assets/images/avatars/4.jpg" alt="User 6" />
      </div>
    </div>
  </div>
  <div>
    <div class="vb__utils__avatarGroup mb-3">
      <div class="vb__utils__avatar vb__utils__avatar--size46 vb__utils__avatar--rounded">
        <img src="assets/images/avatars/1.jpg" alt="User 7" />
      </div>
      <div class="vb__utils__avatar vb__utils__avatar--size46 vb__utils__avatar--rounded">
        <img src="assets/images/avatars/2.jpg" alt="User 8" />
      </div>
      <div class="vb__utils__avatar vb__utils__avatar--size46 vb__utils__avatar--rounded">
        <img src="assets/images/avatars/3.jpg" alt="User 9" />
      </div>
      <div class="vb__utils__avatar vb__utils__avatar--size46 vb__utils__avatar--rounded">
        <img src="assets/images/avatars/4.jpg" alt="User 10" />
      </div>
      <button type="button" class="vb__utils__avatarGroupAdd">
        <i class="fe fe-plus"></i>
      </button>
    </div>
    <div class="d-flex align-items-start">
      <div class="vb__utils__avatar vb__utils__avatar--size64 vb__utils__avatar--rounded mr-2">
        <img src="assets/images/avatars/4.jpg" alt="User 11" />
      </div>
      <div class="vb__utils__avatar vb__utils__avatar--size46 vb__utils__avatar--rounded">
        <img src="assets/images/avatars/4.jpg" alt="User 12" />
      </div>
    </div>
  </div>
</div>