<div class="row">
  <div class="col-lg-6">
    <h5 class="mb-4"><strong>Base</strong></h5>
    <div class="mb-5">
      <h1>Example heading <span class="badge badge-light">New</span></h1>
      <h2>Example heading <span class="badge badge-light">New</span></h2>
      <h3>Example heading <span class="badge badge-light">New</span></h3>
      <h4>Example heading <span class="badge badge-light">New</span></h4>
      <h5>Example heading <span class="badge badge-light">New</span></h5>
      <h6>Example heading <span class="badge badge-light">New</span></h6>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-4"><strong>In Buttons</strong></h5>
    <div class="mb-5"><button class="btn btn-primary">Notifications <span class="badge badge-light">4</span></button>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-4"><strong>Colors</strong></h5>
    <div class="mb-5"><span class="mr-2 badge badge-primary">Primary</span><span
        class="mr-2 badge badge-secondary">Secondary</span><span class="mr-2 badge badge-success">Success</span><span
        class="mr-2 badge badge-danger">Danger</span><span class="mr-2 badge badge-warning">Warning</span><span
        class="mr-2 badge badge-info">Info</span><span class="mr-2 badge badge-light">Light</span><span
        class="mr-2 badge badge-dark">Dark</span></div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-4"><strong>Pills</strong></h5>
    <div class="mb-5"><span class="mr-2 badge badge-primary badge-pill">Primary</span><span
        class="mr-2 badge badge-secondary badge-pill">Secondary</span><span
        class="mr-2 badge badge-success badge-pill">Success</span><span
        class="mr-2 badge badge-danger badge-pill">Danger</span><span
        class="mr-2 badge badge-warning badge-pill">Warning</span><span
        class="mr-2 badge badge-info badge-pill">Info</span><span
        class="mr-2 badge badge-light badge-pill">Light</span><span class="mr-2 badge badge-dark badge-pill">Dark</span>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-4"><strong>Links</strong></h5>
    <div class="mb-5">
      <a href="#" class="mr-2 badge badge-primary">Primary</a>
      <a href="#" class="mr-2 badge badge-secondary">Secondary</a>
      <a href="#" class="mr-2 badge badge-success">Success</a>
      <a href="#" class="mr-2 badge badge-danger">Danger</a><a href="#" class="mr-2 badge badge-warning">Warning</a>
      <a href="#" class="mr-2 badge badge-info">Info</a><a href="#" class="mr-2 badge badge-light">Light</a>
      <a href="#" class="mr-2 badge badge-dark">Dark</a></div>
  </div>
</div>