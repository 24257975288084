<div>
  <div class="row">
    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="card text-center">
        <div class="card-header border-bottom pt-3 pb-3">
          <div class="text-uppercase text-dark font-weight-bold">Ubuntu</div>
        </div>
        <div class="card-body pt-3 pb-3">
          <div class="text-center text-gray-5">18.04 x86</div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="card text-center bg-light border-blue">
        <div class="card-header border-bottom pt-3 pb-3">
          <div class="text-uppercase text-dark font-weight-bold">Freebsd</div>
        </div>
        <div class="card-body pt-3 pb-3">
          <div class="text-center text-gray-5">18.04 x86</div>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="card text-center">
        <div class="card-header border-bottom pt-3 pb-3">
          <div class="text-uppercase text-dark font-weight-bold">Fedora</div>
        </div>
        <div class="card-body pt-3 pb-3">
          <div class="text-center text-gray-5">18.04 x86</div>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12">
      <div class="card text-center">
        <div class="card-header border-bottom pt-3 pb-3">
          <div class="text-uppercase text-dark font-weight-bold">Debian</div>
        </div>
        <div class="card-body pt-3 pb-3">
          <div class="text-center text-gray-5">18.04 x86</div>
        </div>
      </div>
    </div>
  </div>
</div>