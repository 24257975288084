<div>
  <nz-layout>
    <nz-header>Header</nz-header>
    <nz-content>Content</nz-content>
    <nz-footer>Footer</nz-footer>
  </nz-layout>

  <nz-layout>
    <nz-header>Header</nz-header>
    <nz-layout>
      <nz-sider>Sider</nz-sider>
      <nz-content>Content</nz-content>
    </nz-layout>
    <nz-footer>Footer</nz-footer>
  </nz-layout>

  <nz-layout>
    <nz-header>Header</nz-header>
    <nz-layout>
      <nz-content>Content</nz-content>
      <nz-sider>Sider</nz-sider>
    </nz-layout>
    <nz-footer>Footer</nz-footer>
  </nz-layout>

  <nz-layout>
    <nz-sider>Sider</nz-sider>
    <nz-layout>
      <nz-header>Header</nz-header>
      <nz-content>Content</nz-content>
      <nz-footer>Footer</nz-footer>
    </nz-layout>
  </nz-layout>
</div>
