<h5 class="mb-4">
  <strong>Basic Progress</strong>
</h5>
<div class="mb-5">
  <div class="mb-4">
    <ngb-progressbar type="success" [value]="25"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="info" [value]="50"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="warning" [value]="75"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="danger" [value]="100"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="primary" [value]="75"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="secondary" [value]="50"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="dark" [value]="25"></ngb-progressbar>
  </div>
</div>
<h5 class="mb-4">
  <strong>Current Value</strong>
</h5>
<div class="mb-5">
  <div class="mb-4">
    <ngb-progressbar type="success" textType="white" [value]="25" showValue="true"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="dark" textType="white" [value]="50" showValue="true"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="light" textType="success" [value]="75" showValue="true"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="warning" textType="dark" [value]="100" showValue="true"></ngb-progressbar>
  </div>
</div>
<h5 class="mb-4">
  <strong>Striped</strong>
</h5>
<div class="mb-5">
  <div class="mb-4">
    <ngb-progressbar type="success" [value]="25" [striped]="true"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="info" [value]="50" [striped]="true"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="warning" [value]="75" [striped]="true"></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="danger" [value]="100" [striped]="true"></ngb-progressbar>
  </div>
</div>
<h5 class="mb-4">
  <strong>Custom</strong>
</h5>
<div class="mb-5">
  <div class="mb-4">
    <ngb-progressbar type="success" [value]="25">25</ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="info" [value]="50">Copying file <b>2 of 4</b>...</ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="warning" [value]="75" [striped]="true" [animated]="true"><i>50%</i></ngb-progressbar>
  </div>
  <div class="mb-4">
    <ngb-progressbar type="danger" [value]="100" [striped]="true">Completed!</ngb-progressbar>
  </div>
</div>