<div>
  <h5 class="mb-3">
    <strong>Basic</strong>
  </h5>
  <div nz-row class="mb-3">
    <div nz-col nzSpan="12">
      <div class="bg-light" style="padding: 10px">col-12</div>
    </div>
    <div nz-col nzSpan="12">
      <div class="bg-light" style="padding: 10px">col-12</div>
    </div>
  </div>
  <div nz-row class="mb-3">
    <div nz-col nzSpan="8">
      <div class="bg-light" style="padding: 10px">col-8</div>
    </div>
    <div nz-col nzSpan="8">
      <div class="bg-light" style="padding: 10px">col-8</div>
    </div>
    <div nz-col nzSpan="8">
      <div class="bg-light" style="padding: 10px">col-8</div>
    </div>
  </div>
  <div nz-row class="mb-3">
    <div nz-col nzSpan="6">
      <div class="bg-light" style="padding: 10px">col-6</div>
    </div>
    <div nz-col nzSpan="6">
      <div class="bg-light" style="padding: 10px">col-6</div>
    </div>
    <div nz-col nzSpan="6">
      <div class="bg-light" style="padding: 10px">col-6</div>
    </div>
    <div nz-col nzSpan="6">
      <div class="bg-light" style="padding: 10px">col-6</div>
    </div>
  </div>
</div>