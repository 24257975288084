<h5 class="mb-3">
  <strong>Basic</strong>
</h5>
<div class="mb-5">
  <nz-steps [nzCurrent]="1">
    <nz-step nzTitle="Finished" nzDescription="This is a description."></nz-step>
    <nz-step nzTitle="In Progress" nzSubtitle="Left 00:00:08" nzDescription="This is a description."> </nz-step>
    <nz-step nzTitle="Waiting" nzDescription="This is a description."></nz-step>
  </nz-steps>
</div>
<h5 class="mb-3">
  <strong>With Icons</strong>
</h5>
<div class="mb-5">
  <nz-steps>
    <nz-step nzTitle="Login" nzStatus="finish" nzIcon="user"></nz-step>
    <nz-step nzTitle="Verification" nzStatus="finish" nzIcon="solution"></nz-step>
    <nz-step nzTitle="Pay" nzStatus="process" nzIcon="loading"></nz-step>
    <nz-step nzTitle="Done" nzStatus="wait" [nzIcon]="iconTemplate"></nz-step>
    <ng-template #iconTemplate><i nz-icon nzType="smile"></i></ng-template>
  </nz-steps>
</div>
<h5 class="mb-3">
  <strong>Centered</strong>
</h5>
<div class="mb-5">
  <nz-steps [nzCurrent]="1" nzProgressDot>
    <nz-step nzTitle="Finished" nzDescription="This is a description."></nz-step>
    <nz-step nzTitle="In Progress" nzDescription="This is a description."></nz-step>
    <nz-step nzTitle="Waiting" nzDescription="This is a description."></nz-step>
  </nz-steps>
</div>
<h5 class="mb-3">
  <strong>Vertical</strong>
</h5>
<div class="mb-5">
  <nz-steps [nzCurrent]="1" nzDirection="vertical">
    <nz-step nzTitle="Finished" nzDescription="This is a description."></nz-step>
    <nz-step nzTitle="In Progress" nzDescription="This is a description."></nz-step>
    <nz-step nzTitle="Waiting" nzDescription="This is a description."></nz-step>
  </nz-steps>
</div>