import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-hidden-typography',
  templateUrl: './typography.component.html',
  styleUrls: ['./typography.component.scss'],
})
export class VbHiddenTypographyComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
