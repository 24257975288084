import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-app-partials-do-head',
  templateUrl: './do-head.component.html',
  styleUrls: ['./do-head.component.scss'],
})
export class VbAppPartialsDoHeadComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
