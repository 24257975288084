import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-forms-4',
  templateUrl: './4.component.html',
  styleUrls: ['./4.component.scss'],
})
export class VbForms4Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
