<div>
  <h5 class="mb-4"><strong>Default Jumbotron</strong></h5>
  <div class="mb-5">
    <div class="jumbotron">
      <h1 class="font-size-70"><strong>Hello, world!</strong></h1>
      <p class="lead">This is a simple hero unit, a simple Jumbotron-style component for calling extra attention to
        featured content or information.</p>
      <hr class="my-2">
      <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
      <p class="lead"><button class="btn btn-primary">Learn More</button></p>
    </div>
  </div>
  <h5 class="mb-4"><strong>Fluid Jumbotron</strong></h5>
  <div class="jumbotron jumbotron-fluid">
    <div class="container-fluid">
      <h1 class="font-size-70"><strong>Fluid jumbotron</strong></h1>
      <p class="lead">This is a modified jumbotron that occupies the entire horizontal space of its parent.</p>
    </div>
  </div>
</div>