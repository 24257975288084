<div class="row">
  <div class="col-lg-6">
    <h5 class="mb-3">
      <strong>Basic</strong>
    </h5>
    <div class="mb-5">
      <div class="mb-3">
        <nz-card style="width:300px;" nzTitle="Card title" [nzExtra]="extraTemplate">
          <p>Card content</p>
          <p>Card content</p>
          <p>Card content</p>
        </nz-card>
        <ng-template #extraTemplate>
          <a>More</a>
        </ng-template>
      </div>
      <div class="mb-3">
        <nz-card style="width:300px;" nzSize="small" nzTitle="Card title" [nzExtra]="extraTemplate">
          <p>Card content</p>
          <p>Card content</p>
          <p>Card content</p>
        </nz-card>
        <ng-template #extraTemplate>
          <a>More</a>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-3">
      <strong>Advanced</strong>
    </h5>
    <div class="mb-5">
      <div class="mb-3">
        <nz-card style="width:300px;" [nzCover]="coverTemplate"
          [nzActions]="[actionSetting, actionEdit, actionEllipsis]">
          <nz-card-meta nzTitle="Card title" nzDescription="This is the description" [nzAvatar]="avatarTemplate">
          </nz-card-meta>
        </nz-card>
        <ng-template #avatarTemplate>
          <nz-avatar nzSrc="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
        </ng-template>
        <ng-template #coverTemplate>
          <img alt="example" src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png" />
        </ng-template>
        <ng-template #actionSetting>
          <i nz-icon nzType="setting"></i>
        </ng-template>
        <ng-template #actionEdit>
          <i nz-icon nzType="edit"></i>
        </ng-template>
        <ng-template #actionEllipsis>
          <i nz-icon nzType="ellipsis"></i>
        </ng-template>
      </div>
      <div class="mb-3">
        <nz-card style="width: 300px; margin-top: 16px" [nzLoading]="true">
          whatever content
        </nz-card>
      </div>
    </div>
  </div>
</div>