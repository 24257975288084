<div class="d-flex flex-wrap align-items-start">
  <div class="vb__utils__avatar vb__utils__avatar--size64 mr-3">
    <img src="assets/images/avatars/3.jpg" alt="Mary Stanform" />
  </div>
  <div>
    <div class="text-uppercase font-size-12">Administrator</div>
    <div class="text-dark font-weight-bold font-size-18 mb-2">Helen Maggie</div>
    <button type="button" class="btn btn-success btnWithAddon">
      <span class="btnAddon">
        <i class="btnAddonIcon fe fe-plus-circle"></i>
      </span>
      Request Access
    </button>
  </div>
</div>