<div>
  <h5 class="mb-4"><strong>Default Toasts</strong></h5>
  <div class="row mb-3">
    <div class="col-lg-6">
      <div class="p-3 my-2 rounded">
        <div class="toast fade show" role="alert">
          <div class="toast-header"><strong class="mr-auto">Reactstrap</strong></div>
          <div class="toast-body">This is a toast on a white background — check it out!</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="p-3 my-2 rounded bg-docs-transparent-grid">
        <div class="toast fade show" role="alert">
          <div class="toast-header"><strong class="mr-auto">Reactstrap</strong></div>
          <div class="toast-body">This is a toast on a gridded background — check it out!</div>
        </div>
      </div>
    </div>
  </div>
  <h5 class="mb-4"><strong>Colored Toasts</strong></h5>
  <div class="row">
    <div class="col-lg-6">
      <div class="p-3 bg-primary my-2 rounded">
        <div class="toast fade show" role="alert">
          <div class="toast-header"><strong class="mr-auto">Reactstrap</strong></div>
          <div class="toast-body">This is a toast on a primary background — check it out!</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="p-3 bg-secondary my-2 rounded">
        <div class="toast fade show" role="alert">
          <div class="toast-header"><strong class="mr-auto">Reactstrap</strong></div>
          <div class="toast-body">This is a toast on a secondary background — check it out!</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="p-3 bg-success my-2 rounded">
        <div class="toast fade show" role="alert">
          <div class="toast-header"><strong class="mr-auto">Reactstrap</strong></div>
          <div class="toast-body">This is a toast on a success background — check it out!</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="p-3 bg-danger my-2 rounded">
        <div class="toast fade show" role="alert">
          <div class="toast-header"><strong class="mr-auto">Reactstrap</strong></div>
          <div class="toast-body">This is a toast on a danger background — check it out!</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="p-3 bg-warning my-2 rounded">
        <div class="toast fade show" role="alert">
          <div class="toast-header"><strong class="mr-auto">Reactstrap</strong></div>
          <div class="toast-body">This is a toast on a warning background — check it out!</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="p-3 bg-info my-2 rounded">
        <div class="toast fade show" role="alert">
          <div class="toast-header"><strong class="mr-auto">Reactstrap</strong></div>
          <div class="toast-body">This is a toast on an info background — check it out!</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="p-3 bg-dark my-2 rounded">
        <div class="toast fade show" role="alert">
          <div class="toast-header"><strong class="mr-auto">Reactstrap</strong></div>
          <div class="toast-body">This is a toast on a dark background — check it out!</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="p-3 my-2 rounded" style="background: black;">
        <div class="toast fade show" role="alert">
          <div class="toast-header"><strong class="mr-auto">Reactstrap</strong></div>
          <div class="toast-body">This is a toast on a black background — check it out!</div>
        </div>
      </div>
    </div>
  </div>
</div>