<div>
  <div class="d-flex flex-xs-wrap border-bottom">
    <div class="mr-auto pr-3">
      <div class="text-dark font-size-24 font-weight-bold mb-2">
        [Feature Request] How to enable custom font that comes from svg #2460
      </div>
      <div class="mb-3">
        <span class="mr-3 text-uppercase badge badge-success">Open</span>
        <a class="font-weight-bold" href="javascript: void(0);">
          zxs2162
        </a>
        wrote this issue 12 days ago · 0 comments
      </div>
    </div>
    <a class="btn btn-success align-self-start text-nowrap" href="javascript: void(0)">
      New Issue
    </a>
  </div>
</div>