import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-lists-9',
  templateUrl: './9.component.html',
  styleUrls: ['./9.component.scss'],
})
export class VbList9Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
