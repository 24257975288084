import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-app-partials-wp-post-short',
  templateUrl: './wp-post-short.component.html',
  styleUrls: ['./wp-post-short.component.scss'],
})
export class VbAppPartialsWpPostShortComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
