<div class="row">
  <div class="col-lg-6">
    <h5 class="mb-3">
      <strong>Basic</strong>
    </h5>
    <div class="mb-5">
      <span nz-tooltip nzTooltipTitle="prompt text">Tooltip will show when mouse enter.</span>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-3">
      <strong>Aligned</strong>
    </h5>
    <div class="mb-5">
      <button nz-button nzTooltipTitle="prompt text" nzTooltipPlacement="topCenter" nz-tooltip>
        Arrow points to center
      </button>
    </div>
  </div>
</div>