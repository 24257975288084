<div class="row">
  <div class="col-lg-12 mb-5">
    <h5 class="mb-4">
      <strong>Default Group</strong>
    </h5>
    <div class="btn-group mr-2 mb-2">
      <button type="button" class="btn btn-success">Left</button>
      <button type="button" class="btn btn-success">Middle</button>
      <button type="button" class="btn btn-success">Right</button>
    </div>
    <div class="btn-group mr-2 mb-2">
      <button type="button" class="btn btn-rounded">Left</button>
      <button type="button" class="btn">Middle</button>
      <button type="button" class="btn btn-rounded btn-danger">Right</button>
    </div>
  </div>
  <div class="col-lg-12 mb-5">
    <h5 class="mb-4">
      <strong>Nesting Group</strong>
    </h5>
    <div class="btn-group mr-2 mb-2" aria-label="" role="group">
      <button type="button" class="btn btn-primary">1</button>
      <button type="button" class="btn btn-primary">2</button>
      <div ngbDropdown class="btn-group">
        <button class="btn btn-primary" id="group1" ngbDropdownToggle>Dropdown</button>
        <div ngbDropdownMenu aria-labelledby="group1">
          <button ngbDropdownItem>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else here</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Separated link</button>
        </div>
      </div>
    </div>
    <div class="btn-group mr-2 mb-2" aria-label="" role="group">
      <button type="button" class="btn btn-info">1</button>
      <button type="button" class="btn btn-info">2</button>
      <div ngbDropdown class="btn-group">
        <button class="btn btn-info" id="group2" ngbDropdownToggle>Dropdown</button>
        <div ngbDropdownMenu aria-labelledby="group2">
          <button ngbDropdownItem>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else here</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Separated link</button>
        </div>
      </div>
    </div>
    <div class="btn-group mr-2 mb-2" aria-label="" role="group">
      <button type="button" class="btn">1</button>
      <button type="button" class="btn">2</button>
      <div ngbDropdown class="btn-group">
        <button class="btn" id="group3" ngbDropdownToggle>Dropdown</button>
        <div ngbDropdownMenu aria-labelledby="group3">
          <button ngbDropdownItem>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else here</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Separated link</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 mb-3">
    <h5 class="mb-4">
      <strong>Vertical Group</strong>
    </h5>
    <div class="btn-group-vertical mr-2 mb-2">
      <button type="button" class="btn btn-danger">Button</button>
      <div ngbDropdown class="btn-group">
        <button class="btn btn-danger" id="group4" ngbDropdownToggle>Dropdown</button>
        <div ngbDropdownMenu aria-labelledby="group4">
          <button ngbDropdownItem>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else here</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Separated link</button>
        </div>
      </div>
      <button type="button" class="btn btn-danger">Button</button>
      <div ngbDropdown class="btn-group">
        <button class="btn btn-danger" id="group5" ngbDropdownToggle>Dropdown</button>
        <div ngbDropdownMenu aria-labelledby="group5">
          <button ngbDropdownItem>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else here</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Separated link</button>
        </div>
      </div>
    </div>
    <div class="btn-group-vertical mr-2 mb-2">
      <div ngbDropdown class="btn-group">
        <button class="btn btn-default" id="group6" ngbDropdownToggle>Dropdown</button>
        <div ngbDropdownMenu aria-labelledby="group6">
          <button ngbDropdownItem>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else here</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Separated link</button>
        </div>
      </div>
      <button type="button" class="btn btn-default">Button</button>
      <div ngbDropdown class="btn-group">
        <button class="btn btn-default" id="group7" ngbDropdownToggle>Dropdown</button>
        <div ngbDropdownMenu aria-labelledby="group7">
          <button ngbDropdownItem>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else here</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Separated link</button>
        </div>
      </div>
      <button type="button" class="btn btn-default">Button</button>
    </div>
    <div class="btn-group-vertical mr-2 mb-2">
      <div ngbDropdown class="btn-group">
        <button class="btn" id="group8" ngbDropdownToggle>Dropdown</button>
        <div ngbDropdownMenu aria-labelledby="group8">
          <button ngbDropdownItem>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else here</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Separated link</button>
        </div>
      </div>
      <button type="button" class="btn">Button</button>
      <div ngbDropdown class="btn-group">
        <button class="btn" id="group9" ngbDropdownToggle>Dropdown</button>
        <div ngbDropdownMenu aria-labelledby="group9">
          <button ngbDropdownItem>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else here</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Separated link</button>
        </div>
      </div>
      <button type="button" class="btn">Button</button>
    </div>
    <div class="btn-group-vertical mr-2 mb-2">
      <div ngbDropdown class="btn-group">
        <button class="btn btn-primary" id="group10" ngbDropdownToggle>Dropdown</button>
        <div ngbDropdownMenu aria-labelledby="group10">
          <button ngbDropdownItem>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else here</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Separated link</button>
        </div>
      </div>
      <button type="button" class="btn btn-primary">Button</button>
      <div ngbDropdown class="btn-group">
        <button class="btn btn-primary" id="group11" ngbDropdownToggle>Dropdown</button>
        <div ngbDropdownMenu aria-labelledby="group11">
          <button ngbDropdownItem>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else here</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Separated link</button>
        </div>
      </div>
      <button type="button" class="btn btn-primary">Button</button>
    </div>
  </div>
</div>