<div class="card-header-flex">
  <div class="d-flex flex-column justify-content-center mr-auto">
    <h5 class="mb-0">
      <strong>{{ data.title }}</strong>
    </h5>
  </div>
  <nz-tabset class="vb-tabs" [nzSelectedIndex]="0">
    <nz-tab nzTitle="History"></nz-tab>
    <nz-tab nzTitle="Information"></nz-tab>
    <nz-tab nzTitle="Actions"></nz-tab>
  </nz-tabset>
</div>