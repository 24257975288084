import { Component } from '@angular/core'

@Component({
  selector: 'kit-antd-slider-example',
  templateUrl: './slider.component.html',
})
export class KitAntdSliderExampleComponent {
  disabled = false
  value1 = 30
  value2 = [20, 50]
}
