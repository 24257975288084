import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-emails-3',
  templateUrl: './3.component.html',
  styleUrls: ['./3.component.scss'],
})
export class VbEmails3Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
