<div>
  <div class="d-flex flex-wrap align-items-center">
    <div class="vb__utils__avatar vb__utils__avatar--size64 flex-shrink-0 mr-5 mb-3">
      <img src="assets/images/avatars/2.jpg" alt="Mary Stanform" />
    </div>
    <div class="mr-auto mb-3">
      <div class="text-dark font-weight-bold font-size-24">
        <span class="mr-3">Mediatec Software</span>
        <span class="align-middle text-primary text-uppercase font-size-12 badge badge-light">Default</span>
      </div>
      <div>Operational / Developer tooling / Update your project information under Settings</div>
    </div>
    <a class="btn btn-light btn-lg text-blue font-size-14" href="javascript: void(0);">Move assets →</a>
  </div>
  <nz-tabset class="vb-tabs-bordered mb-2" [nzSelectedIndex]="0">
    <nz-tab nzTitle="Resources"></nz-tab>
    <nz-tab nzTitle="Activity"></nz-tab>
    <nz-tab nzTitle="Settings"></nz-tab>
  </nz-tabset>
</div>