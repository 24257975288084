<h5 class="mb-3">
  <strong>Basic</strong>
</h5>
<div class="mb-5">
  <div class="d-inline-block mr-4">
    <nz-badge [nzCount]="5">
      <nz-avatar nzIcon="user" [nzShape]="'square'"></nz-avatar>
    </nz-badge>
  </div>
  <div class="d-inline-block mr-4">
    <nz-badge nzDot>
      <nz-avatar nzIcon="user" [nzShape]="'square'"></nz-avatar>
    </nz-badge>
  </div>
</div>
<h5 class="mb-3">
  <strong>Type</strong>
</h5>
<div class="mb-5">
  <div class="d-inline-block mr-4">
    <nz-avatar nzIcon="user"></nz-avatar>
  </div>
  <div class="d-inline-block mr-4">
    <nz-avatar nzText="U"></nz-avatar>
  </div>
  <div class="d-inline-block mr-4">
    <nz-avatar nzText="USER"></nz-avatar>
  </div>
  <div class="d-inline-block mr-4">
    <nz-avatar nzIcon="user" nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
  </div>
  <div class="d-inline-block mr-4">
    <nz-avatar nzIcon="user" style="background-color:#87d068;"></nz-avatar>
  </div>
  <div class="d-inline-block mr-4">
    <nz-avatar nzIcon="user" style="background-color:#87d068;"></nz-avatar>
  </div>
</div>