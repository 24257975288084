import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-bootstrap-dropdowns-example',
  templateUrl: './dropdowns.component.html',
})
export class KitBootstrapDropdownsExampleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
