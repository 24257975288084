<h5 class="mb-3">
  <strong>Basic</strong>
</h5>
<div class="mb-5">
  <div class="mb-3">
    <nz-progress [nzPercent]="30"></nz-progress>
  </div>
  <div class="mb-3">
    <nz-progress [nzPercent]="50" nzStatus="active"></nz-progress>
  </div>
  <div class="mb-3">
    <nz-progress [nzPercent]="70" nzStatus="exception"></nz-progress>
  </div>
  <div class="mb-3">
    <nz-progress [nzPercent]="100"></nz-progress>
  </div>
  <div class="mb-3">
    <nz-progress [nzPercent]="50" [nzShowInfo]="false"></nz-progress>
  </div>
</div>