<h5 class="mb-4"><strong>Default Tooltip</strong></h5>
<div class="mb-5">
  <p>
    Somewhere in here is a
    <span class="vb__utils__link" placement="top" ngbTooltip="Tooltip on top">
      tooltip
    </span>
    .
  </p>
</div>
<h5 class="mb-4"><strong>Directions</strong></h5>
<div class="mb-5">
  <button type="button" class="btn btn-light mb-3 mr-3" placement="top" ngbTooltip="Tooltip on top">
    Tooltip on top
  </button>
  <button type="button" class="btn btn-light mb-3 mr-3" placement="right" ngbTooltip="Tooltip on right">
    Tooltip on right
  </button>
  <button type="button" class="btn btn-light mb-3 mr-3" placement="bottom" ngbTooltip="Tooltip on bottom">
    Tooltip on bottom
  </button>
  <button type="button" class="btn btn-light mb-3 mr-3" placement="left" ngbTooltip="Tooltip on left">
    Tooltip on left
  </button>
</div>