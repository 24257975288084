<div class="table-operations">
  <button nz-button (click)="sortByAge()">Sort age</button>
  <button nz-button (click)="resetFilters()">Clear filters</button>
  <button nz-button (click)="resetSortAndFilters()">Clear filters and sorters</button>
</div>
<nz-table #filterTable [nzData]="listOfData" nzTableLayout="fixed">
  <thead>
    <tr>
      <th *ngFor="let column of listOfColumns; trackBy: trackByName" [(nzSortOrder)]="column.sortOrder"
        [nzSortFn]="column.sortFn" [nzFilters]="column.listOfFilter" [nzFilterFn]="column.filterFn">
        {{ column.name }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of filterTable.data">
      <td>{{ data.name }}</td>
      <td>{{ data.age }}</td>
      <td>{{ data.address }}</td>
    </tr>
  </tbody>
</nz-table>