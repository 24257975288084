<div class="pt-5">
  <div class="card boxContainer mt-2">
    <div class="text-dark font-size-32 mb-3">
      Reset Password
    </div>
    <form nz-form [nzLayout]="'vertical'">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your email!">
          <input type="text" nz-input placeholder="Email Address" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <button nz-button nzType="primary" class="text-center w-100">
            <strong>Reset my password</strong>
          </button>
        </nz-form-control>
      </nz-form-item>
    </form>
    <a routerLink="/auth/login" class="vb__utils__link">
      <i class="fe fe-arrow-left mr-1 align-middle"></i>
      Go to Sign in
    </a>
  </div>
</div>