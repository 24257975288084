<div class="d-flex flex-wrap flex-column align-items-center">
  <div class="vb__utils__avatar vb__utils__avatar--size64 mb-3">
    <img src="assets/images/avatars/5.jpg" alt="Mary Stanform" />
  </div>
  <div class="text-center">
    <div class="text-dark font-weight-bold font-size-18">Mary Stanform</div>
    <div class="text-uppercase font-size-12 mb-3">Support team</div>
    <button type="button" class="btn btn-primary btnWithAddon">
      <span class="btnAddon">
        <i class="btnAddonIcon fe fe-plus-circle"></i>
      </span>
      Request Access
    </button>
  </div>
</div>