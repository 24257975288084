<div class="text-dark font-size-18 mb-3">
  Pluggable enterprise-level react application framework.
  <a class="text-blue" href="javascript: void(0);">
    https://umijs.org/
  </a>
</div>
<div class="mb-4">
  <a href="javascript: void(0);" class="badge text-blue text-uppercase bg-light font-size-12 mr-2">
    Umi
  </a>
  <a href="javascript: void(0);" class="badge text-blue text-uppercase bg-light font-size-12 mr-2">
    React-framework
  </a>
  <a href="javascript: void(0);" class="badge text-blue text-uppercase bg-light font-size-12 mr-2">
    Umijs
  </a>
</div>
<div class="d-flex flex-wrap justify-content-around border-top border-bottom">
  <div class="mx-3 my-2 text-nowrap">
    <i class="fe fe-arrow-down-right font-size-21 mr-1 align-text-top"></i>
    <strong class="text-dark font-size-18 mr-1">2,128</strong>
    commits
  </div>
  <div class="mx-3 my-2 text-nowrap">
    <i class="fe fe-chevrons-down font-size-21 mr-1 align-text-top"></i>
    <strong class="text-dark font-size-18 mr-1">18</strong>
    branches
  </div>
  <div class="mx-3 my-2 text-nowrap">
    <i class="fe fe-book-open font-size-21 mr-1 align-text-top"></i>
    <strong class="text-dark font-size-18 mr-1">1,286</strong>
    issues
  </div>
  <div class="mx-3 my-2 text-nowrap">
    <i class="fe fe-users font-size-21 mr-1 align-text-top"></i>
    <strong class="text-dark font-size-18 mr-1">26</strong>
    contributes
  </div>
</div>
<div class="d-flex flex-wrap border-bottom mb-3">
  <div class="my-3 mr-3">
    <a class="btn btn-light text-blue dropdown-toggle" href="javascript: void(0);" nz-dropdown [nzDropdownMenu]="menu"
      nzTrigger="click" nzPlacement="bottomRight">
      Branch: Master
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item><a href="javascript:;">Testing</a></li>
        <li nz-menu-item><a href="javascript:;">Developers</a></li>
        <li nz-menu-item><a href="javascript:;">Download</a></li>
      </ul>
    </nz-dropdown-menu>
  </div>
  <a class="btn btn-light text-blue my-3 mr-auto" href="javascript: void(0);">
    New Pull Request
  </a>
  <a class="btn btn-light text-blue my-3 mr-3" href="javascript: void(0);">
    Create New File
  </a>
  <div class="dropdown my-3 mr-3">
    <a class="btn btn-success dropdown-toggle" href="javascript: void(0);" nz-dropdown [nzDropdownMenu]="cloneMenu"
      nzTrigger="click" nzPlacement="bottomRight">
      Clone or Download
    </a>
    <nz-dropdown-menu #cloneMenu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item><a href="javascript:;">Clone</a></li>
        <li nz-menu-item><a href="javascript:;">Download</a></li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>
<div class="d-flex align-items-center flex-wrap mb-4">
  <a href="javascript: void(0);" class="flex-shrink-0 d-flex align-items-center pr-3 mr-auto text-primary mt-1 mb-1">
    <div class="vb__utils__avatar vb__utils__avatar--size27 mr-3 flex-shrink-0">
      <img src="assets/images/avatars/1.jpg" alt="sorrycc" />
    </div>
    <div>sorrycc</div>
  </a>
  <div class="mt-1 mb-1">Latest commit ab2c07f 23 hours ago</div>
</div>