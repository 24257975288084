import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-bootstrap-tooltips-example',
  templateUrl: './tooltips.component.html',
})
export class KitBootstrapTooltipsExampleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
