import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-bootstrap-tabs-example',
  templateUrl: './tabs.component.html',
})
export class KitBootstrapTabsExampleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
