<div class="card-header-flex align-items-center">
  <div class="d-flex flex-column justify-content-center mr-auto">
    <h5 class="mb-0">
      <strong>{{ data.title }}</strong>
    </h5>
  </div>
  <div>
    <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Unlock Account">
      <a class="btn btn-sm btn-light mr-2">
        <i class="fe fe-unlock"></i>
      </a>
    </span>
    <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Mark as important">
      <a class="btn btn-sm btn-light mr-2">
        <i class="fe fe-star"></i>
      </a>
    </span>
    <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Delete user">
      <a class="btn btn-sm btn-light">
        <i class="fe fe-trash"></i>
      </a>
    </span>
  </div>
</div>