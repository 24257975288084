<div>
  <h5 class="mb-4">
    <strong>Default Popovers</strong>
  </h5>
  <div class="mb-5">
    <button type="button" class="btn btn-primary mb-3 mr-3"
      ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover title">
      Launch Popover
    </button>
  </div>
  <h5 class="mb-4">
    <strong>Position</strong>
  </h5>
  <div class="mb-5">
    <button type="button" class="btn btn-light mb-3 mr-3" placement="bottom"
      ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on bottom">
      Popover on bottom
    </button>
    <button type="button" class="btn btn-light mb-3 mr-3" placement="top"
      ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on top">
      Popover on top
    </button>
    <button type="button" class="btn btn-light mb-3 mr-3" placement="right"
      ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on right">
      Popover on right
    </button>
    <button type="button" class="btn btn-light mb-3 mr-3" placement="left"
      ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on left">
      Popover on left
    </button>
  </div>
</div>