<div>
  <blockquote class="blockquote">
    <p class="mb-0">
      Led cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis
      sagittis ipsum. Praesent mauris. at nibh elementum imperdiet. Duis sagittis ipsum. Praesent
      mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget
      nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
      himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.sed cursus ante
      dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet
    </p>
    <footer class="blockquote-footer">
      Someone famous in <cite title="Source Title">Source Title</cite>
    </footer>
  </blockquote>
</div>