import { Component } from '@angular/core'

@Component({
  selector: 'kit-antd-carousel-example',
  templateUrl: './carousel.component.html',
})
export class KitAntdCarouselExampleComponent {
  array = [1, 2, 3, 4]
  effect = 'scrollx'
}
