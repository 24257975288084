import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-app-partials-github-descr',
  templateUrl: './github-descr.component.html',
  styleUrls: ['./github-descr.component.scss'],
})
export class VbAppPartialsGithubDescrComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
