<div class="pt-4">
  <x-chartist [data]="chartData" [type]="'Bar'" [options]="chartOptions" class="height-200 mb-3" [events]="events">
  </x-chartist>
  <div class="d-flex flex-wrap">
    <div class="mr-5 mb-2">
      <div class="text-nowrap text-uppercase text-gray-4">
        <div class="donut success"></div>
        Gross revenue
      </div>
      <div class="font-weight-bold font-size-18 text-dark">+$125,367.36</div>
    </div>
    <div class="mr-5 mb-2">
      <div class="text-nowrap text-uppercase text-gray-4">
        <div class="donut primary"></div>
        Gross Earnings
      </div>
      <div class="font-weight-bold font-size-18 text-dark">+$125,367.36</div>
    </div>
  </div>
</div>