<h5 class="mb-4">
  <strong>Default Collapse</strong>
</h5>
<div class="mb-5">
  <div class="accordion">
    <div class="card">
      <div class="card-header" id="headingOne" (click)="isCollapsed1 = !isCollapsed1"
        [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample">
        <div class="card-title"><span class="accordion-indicator pull-right"><i class="plus fe fe-plus"></i><i
              class="minus fe fe-minus"></i></span><a>Collapsible Group Item #1</a></div>
      </div>
      <div toggler="#headingOne" class="collapse" [ngbCollapse]="isCollapsed1">
        <div class="card-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
          squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
          eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
          shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
          proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
          aesthetic synth nesciunt you probably havent heard of them accusamus labore sustainable VHS.</div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="headingTwo" (click)="isCollapsed2 = !isCollapsed2"
        [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseExample">
        <div class="card-title"><span class="accordion-indicator pull-right"><i class="plus fe fe-plus"></i><i
              class="minus fe fe-minus"></i></span><a>Collapsible Group Item #2</a></div>
      </div>
      <div toggler="#headingTwo" class="collapse" [ngbCollapse]="isCollapsed2">
        <div class="card-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
          squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
          eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
          shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
          proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
          aesthetic synth nesciunt you probably havent heard of them accusamus labore sustainable VHS.</div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="headingThree" (click)="isCollapsed3 = !isCollapsed3"
        [attr.aria-expanded]="!isCollapsed3" aria-controls="collapseExample">
        <div class="card-title"><span class="accordion-indicator pull-right"><i class="plus fe fe-plus"></i><i
              class="minus fe fe-minus"></i></span><a>Collapsible Group Item #3</a></div>
      </div>
      <div toggler="#headingThree" class="collapse" [ngbCollapse]="isCollapsed3">
        <div class="card-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
          squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
          eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
          shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
          proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
          aesthetic synth nesciunt you probably havent heard of them accusamus labore sustainable VHS.</div>
      </div>
    </div>
  </div>
</div>
<h5 class="mb-4">
  <strong>Target Collapse</strong>
</h5>
<div class="mb-3">
  <button type="button" class="btn btn-outline-primary" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
    Toggle
  </button>
</div>
<div class="mb-5">
  <div id="collapseExample" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-body">
        You can collapse this card by clicking Toggle
      </div>
    </div>
  </div>
</div>