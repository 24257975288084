<div>
  <div class="d-block d-sm-none">
    <nz-select nzShowSearch style="width: 100%" [(ngModel)]="selectedItem">
      <nz-option nzValue="btc" nzLabel="BTC" nzCustomContent>
        BTC (Bitcoin)
        <nz-tag [nzColor]="'blue'" class="ml-3">+11.7%</nz-tag>
      </nz-option>
      <nz-option nzValue="snt" nzLabel="SNT" nzCustomContent>
        SNT (Status Network Token)
        <nz-tag [nzColor]="'red'" class="ml-3">-2.3%</nz-tag>
      </nz-option>
      <nz-option nzValue="rads" nzLabel="RADS" nzCustomContent>
        SNT (Status Network Token)
        <nz-tag [nzColor]="'red'" class="ml-3">-7.2%</nz-tag>
      </nz-option>
      <nz-option nzValue="meme" nzLabel="MEME" nzCustomContent>
        MEME (Memetic)
        <nz-tag [nzColor]="'red'" class="ml-3">-11.7%</nz-tag>
      </nz-option>
      <nz-option nzValue="xmr" nzLabel="XMR" nzCustomContent>
        XMR (Monero)
        <nz-tag [nzColor]="'blue'" class="ml-3">67.5%</nz-tag>
      </nz-option>
      <nz-option nzValue="gld" nzLabel="GLD" nzCustomContent>
        GLD (GoldCoin)
        <nz-tag [nzColor]="'red'" class="ml-3">-22.9%</nz-tag>
      </nz-option>
      <nz-option nzValue="neo" nzLabel="NEO" nzCustomContent>
        NEO (Neo)
        <nz-tag [nzColor]="'red'" class="ml-3">-12.3%</nz-tag>
      </nz-option>
      <nz-option nzValue="btg" nzLabel="BTG" nzCustomContent>
        BTG (Bitcoin Gold)
        <nz-tag [nzColor]="'blue'" class="ml-3">+4.3%</nz-tag>
      </nz-option>
      <nz-option nzValue="xrp" nzLabel="XRP" nzCustomContent>
        XRP (Ripple)
        <nz-tag [nzColor]="'red'" class="ml-3">-4.2%</nz-tag>
      </nz-option>
      <nz-option nzValue="zec" nzLabel="ZEC" nzCustomContent>
        ZEC (ZCash)
        <nz-tag [nzColor]="'red'" class="ml-3">-1.7%</nz-tag>
      </nz-option>
      <nz-option nzValue="zcl" nzLabel="ZCL" nzCustomContent>
        ZCL (ZClassic)
        <nz-tag [nzColor]="'red'" class="ml-3">-2.8%</nz-tag>
      </nz-option>
    </nz-select>
  </div>
  <div class="d-none d-sm-block">
    <a href="javascript: void(0);" class="listItem">
      <span class="listPercents">
        <span>+11.7%</span>
        0.00016985
      </span>
      <span class="listCurrency">
        <span>BTC</span>
        97.20
      </span>
    </a>
    <a href="javascript: void(0);" class="listItem">
      <span class="listPercents">
        <span>+67.5%</span>
        0.00016985
      </span>
      <span class="listCurrency">
        <span>XMR</span>
        8.26
      </span>
    </a>
    <a href="javascript: void(0);" class="listItem listItemNegative">
      <span class="listPercents">
        <span>-22.9%</span>
        0.00016985
      </span>
      <span class="listCurrency">
        <span>GLD</span>
        5.20
      </span>
    </a>
    <a href="javascript: void(0);" class="listItem listItemNegative">
      <span class="listPercents">
        <span>-12.3%</span>
        0.00016985
      </span>
      <span class="listCurrency">
        <span>NEO</span>
        3.20
      </span>
    </a>
    <a href="javascript: void(0);" class="listItem">
      <span class="listPercents">
        <span>+4.3%</span>
        0.00036234
      </span>
      <span class="listCurrency">
        <span>BTG</span>
        5.64
      </span>
    </a>
    <a href="javascript: void(0);" class="listItem listItemNegative">
      <span class="listPercents">
        <span>-4.2%</span>
        0.00035685
      </span>
      <span class="listCurrency">
        <span>XRP</span>
        6.36
      </span>
    </a>
    <a href="javascript: void(0);" class="listItem listItemNegative">
      <span class="listPercents">
        <span>-1.7%</span>
        0.00000985
      </span>
      <span class="listCurrency">
        <span>ZEC</span>
        6.35
      </span>
    </a>
    <a href="javascript: void(0);" class="listItem listItemNegative">
      <span class="listPercents">
        <span>-2.8%</span>
        0.00000152
      </span>
      <span class="listCurrency">
        <span>ZCL</span>
        1.03
      </span>
    </a>
  </div>
</div>