import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-controls-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class VbControlsPaginationComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
