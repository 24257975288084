<div class="row">
  <div class="col-lg-6">
    <h5 class="mb-3">
      <strong>Basic</strong>
    </h5>
    <div class="mb-5">
      <nz-tree-select style="width: 250px" [nzExpandedKeys]="expandKeys" [nzNodes]="nodes" nzShowSearch
        nzPlaceHolder="Please select" [(ngModel)]="value" (ngModelChange)="onChange($event)">
      </nz-tree-select>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-3">
      <strong>Multiple</strong>
    </h5>
    <div class="mb-5">
      <nz-tree-select style="width: 250px" [nzExpandedKeys]="expandKeys" [nzNodes]="nodes" nzShowSearch
        nzPlaceHolder="Please select" [(ngModel)]="value" (ngModelChange)="onChange($event)" [nzMultiple]="true">
      </nz-tree-select>
    </div>
  </div>
</div>