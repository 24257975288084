import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-app-partials-do-server',
  templateUrl: './do-server.component.html',
  styleUrls: ['./do-server.component.scss'],
})
export class VbAppPartialsDoServerComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
