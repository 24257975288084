import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-general-20',
  templateUrl: './20.component.html',
  styleUrls: ['./20.component.scss'],
})
export class VbGeneral20Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
