<h5 class="mb-3">
  <strong>Basic</strong>
</h5>
<div class="mb-5">
  <nz-tabset>
    <nz-tab nzTitle="Tab 1">
      Content of Tab Pane 1
    </nz-tab>
    <nz-tab nzTitle="Tab 2">
      Content of Tab Pane 2
    </nz-tab>
    <nz-tab nzTitle="Tab 3">
      Content of Tab Pane 3
    </nz-tab>
  </nz-tabset>
</div>
<h5 class="mb-3">
  <strong>Card</strong>
</h5>
<div class="mb-5">
  <nz-tabset [nzType]="'card'">
    <nz-tab nzTitle="Tab 1">
      Content of Tab Pane 1
    </nz-tab>
    <nz-tab nzTitle="Tab 2">
      Content of Tab Pane 2
    </nz-tab>
    <nz-tab nzTitle="Tab 3">
      Content of Tab Pane 3
    </nz-tab>
  </nz-tabset>
</div>